import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Input from "../../../inputs/text-input";
import NumberFormat from "react-number-format";

import messageService from "../../../../services/phoneService";

import Button from "../../../button";
import VerificationMessage from "../../../verification-message";

import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResetPassword = ({ handleShowPassword, showPassword }) => {
  const [inputData, setInputData] = useState({
    username: "",
    phoneNumber: "",
    tempPassword: "",
    newPassword: "",
    newPwConfirmation: "",
  });
  let [sentTempPassword, setSentPassword] = useState(false);
  let [tempPasswordVerified, setTempPasswordVerified] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  let [userMessage, setUserMessage] = useState("");

  const isSuccessMessage =
    userMessage ===
      "Temporary Password Verified - Please Create New Password" ||
    userMessage === "Password Successfully Updated" ||
    userMessage ===
      "Temporary Password Sent To Mobile - Please Enter Temporary Password";

  useEffect(() => {}, []);

  const sendTemporaryPassword = async (e) => {
    e.preventDefault();
    let data = await messageService.sendForgotPassword(
      inputData.phoneNumber,
      inputData.username
    );
    setUserMessage(data.data);

    if (
      data.data ===
      "Temporary Password Sent To Mobile - Please Enter Temporary Password"
    ) {
      setSentPassword(true);
    } else {
      console.log("nope, try again");
    }
  };

  const verifyTempPassword = async (e) => {
    e.preventDefault();
    let data = await messageService.verifyTemporaryPassword(
      inputData.tempPassword,
      inputData.username
    );
    setUserMessage(data.data);
    if (
      data.data === "Temporary Password Verified - Please Create New Password"
    ) {
      setTempPasswordVerified(true);
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    let data = await messageService.resetPassword(
      inputData.newPassword,
      inputData.username
    );

    setUserMessage(data.data);
    if (data.data === "Password Successfully Updated") {
      setTempPasswordVerified(true);
    }
  };

  const handleInputChange = (key, value) => {
    setInputData({ ...inputData, [key]: value });

    if (inputData.newPassword === value) {
      setPasswordConfirmed(true);
    } else {
      setPasswordConfirmed(false);
    }
  };

  return (
    <div className="login-content">
      <section className="form-wrapper">
        <h2>Reset Password</h2>
        {!sentTempPassword && (
          <form className="login-form text-center">
            <div>
              <Input
                type="text"
                label="Username"
                value="Username"
                handleInputChange={(e) =>
                  handleInputChange("username", e.target.value)
                }
              />
              <div className="form-group">
                <label htmlFor="mobile">Phone Number</label>
                <NumberFormat
                  id="mobile"
                  value={inputData.phoneNumber}
                  type="text"
                  format="(###) ###-####"
                  mask="_"
                  minLength="17"
                  placeholder="(___) ___ - ____"
                  onChange={(e) =>
                    handleInputChange("phoneNumber", e.target.value)
                  }
                ></NumberFormat>
              </div>
              <Button
                disabledConditions={
                  !inputData.username || !inputData.phoneNumber
                }
                handleClick={((e) => e, sendTemporaryPassword)}
                label="Send Temporary Password"
              />
            </div>
          </form>
        )}

        {sentTempPassword && !tempPasswordVerified && (
          <form className="login-form text-center">
            <div>
              <Input
                type="text"
                label="Enter Temporary Password"
                value={inputData.tempPassword}
                handleInputChange={(e) =>
                  handleInputChange("tempPassword", e.target.value)
                }
              />
              <Button
                disabledConditions={!inputData.tempPassword}
                handleClick={((e) => e, verifyTempPassword)}
                label=" Verify Temporary Password"
              />
            </div>
          </form>
        )}

        {tempPasswordVerified && (
          <form className="login-form text-center">
            <div>
              <Input
                type={showPassword ? "text" : "password"}
                label="Enter New Password"
                value={inputData.newPassword}
                handleInputChange={(e) =>
                  handleInputChange("newPassword", e.target.value)
                }
                icon={
                  !showPassword ? (
                    <FaEye className="icon" />
                  ) : (
                    <FaEyeSlash className="icon" />
                  )
                }
                handleShowPassword={handleShowPassword}
              />
              <Input
                type={showPassword ? "text" : "password"}
                label="Confirm New Password"
                value={inputData.newPwConfirmation}
                handleInputChange={(e) =>
                  handleInputChange("newPwConfirmation", e.target.value)
                }
                icon={
                  !showPassword ? (
                    <FaEye className="icon" />
                  ) : (
                    <FaEyeSlash className="icon" />
                  )
                }
                handleShowPassword={handleShowPassword}
              />
              <Button
                disabledConditions={!passwordConfirmed}
                handleClick={resetPassword}
                label="Submit Updated Password"
              />
            </div>
          </form>
        )}

        {userMessage !== "" && (
          <VerificationMessage
            isSuccessMessage={isSuccessMessage}
            userMessage={userMessage}
          />
        )}

        {userMessage === "Password Successfully Updated" && (
          <div className="text-center">
            <p>
              Click the link below to return to login page and login with new
              password
            </p>
          </div>
        )}
        <hr />
        <div className="text-center">
          <Link to={"/"}>RETURN TO LOGIN? </Link>
        </div>
      </section>
    </div>
  );
};

export default ResetPassword;
