import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

import messageService from "../../../../services/phoneService";

import Button from "../../../button";
import VerificationMessage from "../../../verification-message";

const ForgotUsername = ({ onUserUpdate }) => {
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [userMessage, setUserMessage] = useState("");

  const isSuccessMessage = userMessage === "Username Sent To Mobile";

  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userResponse = await messageService.sendForgotUser(
      phoneNumber
    );
    setUserMessage(userResponse.data);
  };

  return (
    <div className="login-content">
      <section className="form-wrapper">
        <h2>Forgot Username</h2>
        <form
          className="login-form text-center"
          onSubmit={((e) => e, handleSubmit)}
        >
          <div className="form-group">
            <label htmlFor="mobile">Phone Number</label>
            <NumberFormat
              id="mobile"
              value={phoneNumber}
              type="text"
              format="(###) ###-####"
              mask="_"
              minLength="17"
              placeholder="(___) ___ - ____"
              onChange={(e) => setPhoneNumber(e.target.value)}
            ></NumberFormat>
          </div>

          <Button
            label="Submit"
            type="Submit"
            disabledConditions={!phoneNumber}
          />
          {userMessage !== "" && (
            <VerificationMessage
              isSuccessMessage={isSuccessMessage}
              userMessage={userMessage}
            />
          )}
          <hr />
          <div>
            <Link to={"/"}>RETURN TO LOGIN? </Link>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ForgotUsername;
