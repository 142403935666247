import http from "./httpService";
import apiUrl from "../config.json";
import authService from "./authService";

export function getAgencyID(sembraKey) {
    const apiEndpoint = apiUrl.apiUrl + "/getAgencyID";

    return http.post(apiEndpoint, {sembraKey:sembraKey}, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` } });
}

export function getAgencyData() {
    const apiEndpoint = apiUrl.apiUrl + "/getAgencyData";
    return http.post(apiEndpoint, {username:authService.getCurrentUser()}, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` } });
}

export function saveAgencyData(agencyID,saveData) {
    const apiEndpoint = apiUrl.apiUrl + "/saveAgencyData";
    
    return http.post(apiEndpoint, {id:agencyID,saveData:saveData}, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` } });
}

export function addValidAgencyUser(username, agencyID, userJWT) {
    const apiEndpoint = apiUrl.apiUrl + "/addValidAgencyUser";
    
    return http.post(apiEndpoint, {username:username,id:agencyID}, {
        headers: { Authorization: `Bearer ${userJWT}` } });
}

export default {
    getAgencyID,
    getAgencyData,
    saveAgencyData,
    addValidAgencyUser
}
// import http from "./httpService";
// import apiUrl from "../config.json";
// import authService from "./authService";

// export async function getAgencyID(sembraKey) {
//   // const apiEndpoint = apiUrl.apiUrl + "/getAgencyID";
//   const apiEndpoint = apiUrl.apiUrl + `/getAgency-id/${sembraKey}`;

//   return await http.post(
//     apiEndpoint,
//     { sembraKey: sembraKey },
//     {
//       headers: { Authorization: `Bearer ${authService.getJwt()}` },
//     }
//   );

//   //   console.log(test);
// }

// export function getAgencyData() {
//   const apiEndpoint = apiUrl.apiUrl + "/get-agency-data";
//   return http.post(
//     apiEndpoint,
//     { username: authService.getCurrentUser() },
//     {
//       headers: { Authorization: `Bearer ${authService.getJwt()}` },
//     }
//   );
// }

// export function saveAgencyData(agencyID, saveData) {
//   const apiEndpoint = apiUrl.apiUrl + "/save-agency-data";

//   return http.post(
//     apiEndpoint,
//     { id: agencyID, saveData: saveData },
//     {
//       headers: { Authorization: `Bearer ${authService.getJwt()}` },
//     }
//   );
// }

// export function addValidAgencyUser(username, agencyID) {
//   const apiEndpoint = apiUrl.apiUrl + "/add-valid-agency-user";

//   const userJWT = localStorage.getItem("token");

//   return http.post(
//     apiEndpoint,
//     { username: username, id: agencyID },
//     {
//       headers: { Authorization: `Bearer ${userJWT}` },
//     }
//   );
// }

// export function addAgencyEvent(agencyID, newEvent) {
//   const apiEndpoint = apiUrl.apiUrl + "/add-agency-event";

//   const userJWT = localStorage.getItem("token");

//   return http.post(
//     apiEndpoint,
//     { newEvent:newEvent, id: agencyID },
//     {
//       headers: { Authorization: `Bearer ${userJWT}` },
//     }
//   );
// }

// export default {
//   getAgencyID,
//   getAgencyData,
//   saveAgencyData,
//   addValidAgencyUser,
//   addAgencyEvent
// };
