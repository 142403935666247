module.exports = [
    // { docName: "MessengerParticipation", docTitle: "Sembra Messenger Updated Agreement", 
    // docTaxID: true, formURL:"" },
    // {
    //     docName: "UpdatedCSA", docTitle: "SembraCare Updated Customer Service Agreement",
    //     docTaxID: false, formURL: "https://3.141.17.198/mhwkrydewfvkayr/csav2"
    // }
    {
        docName: "MessengerParticipation", docTitle: "2024 Sembra Messenger Updated Agreement",
        docTaxID: true, formURL: "http://3.141.17.198/mhwkrydewfvkayr/messengeragreement2024"     
    }
]
