import React, { useState, useEffect } from "react";
import TextInput from "../../inputs/text-input";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import authService from "../../../services/authService";

import VerificationMessage from "../../verification-message";

import Button from "../../button";

import Version from "./version";

const Login = ({
  onUserUpdate,
  handleNav,
  handleShowPassword,
  showPassword,
}) => {
  const [inputData, setInputData] = useState({ username: "", password: "" });
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (key, value) => {
    setInputData({ ...inputData, [key]: value });
  };

  // const handleShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userResponse = await authService.login(
        inputData.username,
        inputData.password
      );
      if (userResponse) {
        if (userResponse === "User Not Found") {
          setLoginError(true);
          setPasswordError(false);
          setUserMessage("User Not Found");
        } else if (userResponse === "Wrong Password for this user") {
          setLoginError(false);
          setPasswordError(true);
          setUserMessage("Incorrect Password");
        } else if (
          userResponse === "User Not Found" &&
          userResponse === "Wrong Password for this user"
        ) {
          setLoginError(true);
          setUserMessage("User Not Found");
        }
      } else {
        onUserUpdate();
        // window.location = "/welcome";
        handleNav("", "Welcome");
      }
    } catch (ex) {
      setUserMessage(ex);
    }
  };

  useEffect(() => {
    if (!inputData.username) {
      setShowPasswordInput(false);
    }
  }, [showPasswordInput]);

  return (
    <div className="login-content">
      <section className="form-wrapper">
        <h2>Login</h2>
        <form className="login-form text-center" onSubmit={handleSubmit}>
          <TextInput
            label="Username"
            type="text"
            handleInputChange={(e) =>
              handleInputChange("username", e.target.value)
            }
          />

          {showPasswordInput && (
          <TextInput
            label="Password"
            // type="password"
            type={showPassword ? "text" : "password"}
            handleInputChange={(e) =>
              handleInputChange("password", e.target.value)
            }
            icon={
              !showPassword ? (
                <FaEye className="icon" />
              ) : (
                <FaEyeSlash className="icon" />
              )
            }
            handleShowPassword={handleShowPassword}
          />
          )}

          {!showPasswordInput ? (
            <Button
              disabledConditions={!inputData.username}
              handleClick={(e) => setShowPasswordInput(true)}
              label="Next"
            />
          ) : (
            <Button
              disabledConditions={!inputData.password}
              type="submit"
              label="Next"
            />
          )}
          {loginError && (
            <VerificationMessage
              isSuccessMessage={false}
              userMessage={userMessage}
            />
          )}
          {passwordError && (
            <VerificationMessage
              isSuccessMessage={false}
              userMessage={userMessage}
            />
          )}
        </form>
        <hr />
        <div className="text-center">
          <Link to={"/forgot-username"}>FORGOT USER NAME </Link>
          <br />
          <br />
          <Link to={"/reset-password"}>FORGOT PASSWORD? </Link>
        </div>
        <Version />
      </section>
    </div>
  );
};

export default Login;