import React from "react";

export default function Contact() {
  return (
    <address className="contact">
      <strong>Sembra Messenger</strong>
      <br />
      <strong>Email:</strong>{" "}
      <a href="mailto:messenger@sembracare.com">Messenger@sembracare.com</a> <br />
    </address>
  );
}
