import ReUserMessageact, { useState, useEffect } from "react";
// import { PDFDocument, StandardFonts, lineSplit } from "pdf-lib";
// import Checkbox from "../../inputs/checkbox";
// import SignatureFunctionality from "./signature-functionality";
// import UserMessage from "../../user-message";
// import sampleDoc from "../../../assets/pdfs/ParticipationAgreement.pdf";
// import newMessengerDoc from "../../../assets/pdfs/2024ParticipationAgreement.pdf"
// import { getUserData } from "../../../services/authService";
import DocFill from "./doc-fill";
import docService from "../../../services/docService";
import agencyService from "../../../services/agencyService";
import userService from "../../../services/userService";
import { Form, submission } from "@formio/react";
import messengerForm from "../../../assets/objects/messengerForm"

export default function AttnDocsModalBody({
  agencyData,
  userData,
  selectedDocument,
  handleUserUpdate,
  closeModal,
  handleTimedMessage,
  taxIDFullList
}) {
  const [sigSmsSent, setSigSmsSent] = useState(false);
  const [docId, setDocId] = useState([]);
  const [docTermsAccepted, setDocTermsAccepted] = useState(false);
  const [docSigned, setDocSigned] = useState(false);
  const [docTermsChecked, setDocTermsChecked] = useState(false);
  const [pdfDoc, setPdfDoc] = useState("");
  const [pdfInfo, setPdfInfo] = useState("");

  const [formData, setFormData] = useState(null);
  const [userProfileData, setUserProfileData] = useState(null);

  // Fetch form data
  async function getForm() {
    try {
      // const response = await fetch(selectedDocument.docPath, {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });

      // if (!response.ok) {
      //   throw new Error("Failed to fetch form data");
      // }

      // const formData = await response.json();

      // console.log(formData);

      setFormData(messengerForm);
      // console.log(userData);
    } catch (err) {
      console.error("Error fetching form data:", err.message);
    }
  }

  async function getUserData() {
    // let userData = await userService.getUserData();
    if (userData)
      setUserProfileData(userData.userData);
  }

  useEffect(() => {
    getForm();
    getUserData();
  }, []);

  const renderForm = () => {
    if (!formData) {
      return <div>Loading form...</div>;
    }

    return (
      // <div>
      <Form
        form={formData}
        // src={formUrl}
        submission={{ data: { agencyName: userData.userData.name, agencyTitle: userData.userData.title } }}
        // options={formOptions}
        // onChange={handleOptionsChange}
        onSubmit={async (submission) => {
          // Handle form submission as needed
          let submittedData = submission.data;

          await docService.createDoc(
            selectedDocument.docTitle,
            userData.userData.name,
            selectedDocument.agencyName,
            userData.userData.mobile
          ).then(async newDocCreate => {
            setDocId(newDocCreate._id);
            const pdfReturn = await DocFill(
              selectedDocument,
              submittedData.messengerAgreementSignature,
              taxIDFullList
            );
            setPdfDoc(pdfReturn);
            submitFinalDoc(pdfReturn,newDocCreate._id);
          }).catch((err) => {
            console.log(err);
          });
        }}

        onError={(error) => {
          console.log(error)
        }}
      />
      // </div>
    );
  };

  const handleCheckbox = () => {
    setDocTermsChecked(!docTermsChecked);
  };

  const handleSigSms = () => {
    const userData = getUserData();
    setDocTermsAccepted(true);
    docService.createDoc(
      selectedDocument.docTitle,
      userData.name,
      selectedDocument.agencyName,
      userData.phone_number
    )
      .then((newDoc) => {
        setDocId(newDoc._id);
        setSigSmsSent(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDocSign = () => {
    setDocSigned(true);
  };

  // const getSignedDoc = (signedDoc) => {
  //   setPdfDoc(signedDoc);
  // };

  const submitFinalDoc = (pdfReturn,newDocID) => {
    handleTimedMessage(
      `"${docNamePretty}" for TaxId ${selectedDocument.docTaxID} successfully signed`
    );
    docService.setFinalDoc(newDocID, {
      finalDoc: pdfReturn, // prod
      // finalDoc: "BAA base64 doc", // local
    });

    agencyData.map((agency, index) => {
      let existingContracts = agency.contractInfo;
      if (agency.companyData.name === selectedDocument.agencyName) {
        // if (!existingContracts) {
        //   let currentContractInfo = {
        //     contractInfo: { MessengerParticipation: [{ taxID: selectedDocument.docTaxID, Messenger: docId }] },
        //   };
        //   agencyService.saveAgencyData(
        //     agency._id,
        //     Object.assign(agency, currentContractInfo)
        //   );
        // } else {
        if (
          selectedDocument.docTitle ===
          "Sembra Messenger Participtation Contract" ||
          "2024 Sembra Messenger Updated Agreement"
        ) {
          if (existingContracts.MessengerParticipation.length == 0) {
            agencyService.saveAgencyData(agency._id, {
              contractInfo: {
                ...agency.contractInfo,
                MessengerParticipation: [
                  { taxID: selectedDocument.docTaxID, Messenger: newDocID },
                ],
              },
            });
          } else {
            let newMessengerParticipation = [
              ...existingContracts.MessengerParticipation,
              { taxID: selectedDocument.docTaxID, Messenger: newDocID },
            ];
            agencyService.saveAgencyData(agency._id, {
              contractInfo: {
                ...agency.contractInfo,
                MessengerParticipation: newMessengerParticipation,
              },
            });
          }
        }
        if (
          selectedDocument.docTitle ===
          "SembraCare Updated Customer Service Agreement"
        ) {
          agencyService.saveAgencyData(agency._id, {
            contractInfo: {
              ...agency.contractInfo,
              UpdatedCSA: docId,
            },
          });
        }
        // }
      }
    });
    handleUserUpdate();
    closeModal();
  };

  const docNamePretty = selectedDocument.docName.split(/(?=[A-Z])/).join(" ");

  const docTerms = `"I have read and understand the terms and conditions of the ${docNamePretty}. By checking this box I confirm that I am duly authorized to sign the ${docNamePretty} on behalf of Provider and legally empowered to contractually bind Provider to the ${docNamePretty} and related documents."`;

  return (
    <div>
      <div className="mb-md">
        <h3>{docNamePretty}</h3>

        {/* <a target="_blank" href={sampleDoc}>
          Review Sample {docNamePretty} Document
        </a> */}
      </div>

      <div>
        {/* <object src="../../../assets/pdfs/ParticipationAgreement.pdf" width="100%" height="600px" /> */}
        {/* <iframe width="100%" height="400" data="./2024ParticipationAgreement.pdf" type="application/pdf">   </iframe> */}
      </div>

      {!docSigned ? (
        <div id="formio">
          {renderForm()}
        </div>
      ) : (
        <div></div>
      )}

      {/* {docTermsAccepted ? (
        <>
          {!docSigned ? (
            <UserMessage
              message={`Terms and conditions were accepted - Please check your mobile
           device to sign Business Associate Agreement.`}
              variant="success"
            />
          ) : (
            <UserMessage
              message={`Terms and conditions were accepted.`}
              variant="success"
            />
          )}
        </>
      ) : (
        <Checkbox label={docTerms} handleCheckbox={handleCheckbox} />
      )} */}

      {/* <div className="text-center">
        {!sigSmsSent && (
          <button
            // className="sc-btn"
            className={docTermsChecked ? "sc-btn" : "disabled-btn"}
            disabled={docTermsChecked ? false : true}
            onClick={handleSigSms}
          >
            Send SMS to sign
          </button>
        )}
        {sigSmsSent && (
          // !docSigned &&
          <SignatureFunctionality
            userData={userData}
            selectedDocument={selectedDocument}
            docId={docId}
            onDocSign={handleDocSign}
            docSigned={docSigned}
            getSignedDoc={getSignedDoc}
            pdfDoc={pdfDoc}
            docNamePretty={docNamePretty}
            taxIDFullList={taxIDFullList}
          />
        )}
      </div>
      {docSigned && (
        <div className="text-center mt-xl">
          <button
            className="sc-btn"
            // onClick={handleSubmit}
            onClick={submitFinalDoc}
          >
            Submit Final Document
          </button>
        </div>
      )} */}
    </div>
  );
}
