// import Button from "../button";
export default function TextInput({
  label,
  handleInputChange,
  handleShowPassword,
  type,
  disableInput,
  icon,
  placeholder,
  validationSuccess,
  validationMessage,
}) {
  return (
    <div>
      <div className="form-group">
        <label htmlFor={label}>{label}</label>
        <div className={icon ? "icon-group" : ""}>
          <input
            id={label}
            type={type}
            onChange={handleInputChange}
            disabled={disableInput}
            placeholder={placeholder}
            className={
              validationMessage && !validationSuccess ? "validation-err" : ""
            }
          ></input>
          <a
            className={`icon ${disableInput ? "icon-disabled" : ""}`}
            onClick={handleShowPassword}
          >
            {icon}
          </a>
        </div>
        <p
          className={`input-validation ${
            validationSuccess ? "input-success" : "input-error"
          }`}
        >
          {validationMessage}
        </p>
      </div>
    </div>
  );
}
