import React from "react";
import PoweredByLogo from "../../assets/images/sembraCare.png";
import { FiLogOut } from "react-icons/fi";

const Footer = ({ handleLogoutModal }) => {
  return (
    <footer>
      <img src={PoweredByLogo} alt="sembracare-poweredby-logo"></img>
      <FiLogOut className="logout-icon" onClick={handleLogoutModal} />
    </footer>
  );
};

export default Footer;
