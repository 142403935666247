import React, { useState } from "react";
import Section from "../../../components/layout/section";
import Button from "../../button";
import DocFill from "./doc-fill";
import docService from "../../../services/docService";
// import UpToDateDocs from "./up-to-date-docs";
// import messengerDoc from "../../../assets/pdfs/ParticipationAgreement.pdf";

export default function FinalDocuments({
  upToDateDocs,
  taxIDFullList,
  counterDocs,
}) {
  // const handlePdf = async (selectedDoc, docType) => {
  //   console.log(selectedDoc, docType);
  //   if (selectedDoc.counterDoc) {
  //     docService.getDoc(selectedDoc._id);
  //   }

  //   let data = await docService.getDoc(selectedDoc._id);
  //   console.log(data);

  //   var file_path = data.docType;
  //   var a = document.createElement("A");
  //   a.href = file_path;
  //   a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // };

  const handlePendingPdf = async (selectedDoc) => {
    let data = await docService.getDoc(selectedDoc.docID);
    var file_path = data.finalDoc;
    var a = document.createElement("A");
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleCounterPdf = async (selectedDoc) => {
    if (selectedDoc.counterDoc) {
      docService.getDoc(selectedDoc._id);
    }
    let { data: counterDoc } = await docService.getDoc(selectedDoc._id);
    let data = await docService.getDoc(selectedDoc._id);
    var file_path = data.counterDoc;
    var a = document.createElement("A");
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  //TEST PDF FUNCTION
  // const handleTestPDF = async () => {
  //   await docService.checkDoc(upToDateDocs[0].docID).then(async (selectedDoc) => {
  //     let finalDoc = await DocFill(upToDateDocs[0],selectedDoc.docSignature, taxIDFullList);
  //     var file_path = finalDoc;
  //     var a = document.createElement("A");
  //     a.href = file_path;
  //     a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //   })
  // }

  return (
    <main>
      <Section
        title="Documents"
        body={
          <p>
            Below, you can see document status and access copies of
            countersigned agreements
          </p>
        }
      />
      <Section
        title="Documents Pending Review"
        body={
          upToDateDocs.length > 0 ? (
            <>
              {/* <Button
                label="Generate Test Document"
                handleClick={(e) => handleTestPDF()}
              /> */}
              <p>The following documents are pending review by SembraCare</p>
              <hr />
              <ul className="no-list-style">
                {upToDateDocs.map((doc, index) => (
                  <li key={index}>
                    <div className="pending-docs">
                      <p>
                        <strong>Provider:</strong> {doc.agencyName}
                      </p>
                      <p>
                        <strong>Document Title:</strong>{" "}
                        {doc.docName.split(/(?=[A-Z])/).join(" ")}
                      </p>
                      <p>
                        <strong>Tax ID:</strong> {doc.docTaxID}
                      </p>
                      <Button
                        label="Download Pending Document"
                        handleClick={(e) =>
                          handlePendingPdf(upToDateDocs[index])
                        }
                      />
                    </div>
                    <hr />
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p>
              All documents have been approved and signed by SembraCare. No
              documents pending review at this time.
            </p>
          )
        }
      />

      <Section
        title="Countersigned Documents"
        body={
          counterDocs.length > 0 ? (
            <>
              {/* <Button
                label="Generate Test Document"
                handleClick={(e) => handleTestPDF()}
              /> */}
              <p>
                The following documents have been accepted and countersigned by
                SembraCare
              </p>
              <hr />
              <ul className="no-list-style">
                {counterDocs.map((doc, index) => (
                  <li key={index}>
                    <div className="pending-docs">
                      <p>
                        <strong>Provider:</strong> {doc.docCompany}
                      </p>
                      <p>
                        <strong>Document Title:</strong> {doc.docTitle}
                      </p>
                      <p>
                        <strong>Tax ID:</strong> Working on this
                      </p>
                      <Button
                        label="Download Countersigned Document"
                        handleClick={(e) =>
                          handleCounterPdf(counterDocs[index])
                        }
                      />
                    </div>
                    <hr />
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p>
              All documents have been approved and signed by SembraCare. No
              documents pending review at this time.
            </p>
          )
        }
      />
    </main>
  );
}
