export default function VerificationMessage({ isSuccessMessage, userMessage }) {
  return (
    <div>
      <p
        className={`text-center ${
          isSuccessMessage ? "verification-message success" : "verification-message error"
        }`}
      >
        {userMessage}
      </p>
    </div>
  );
}


