import React, { useState, useEffect } from "react";

import Section from "../../layout/section";
import Contact from "../../contact";
import { FaUserCircle } from "react-icons/fa";
// import FormioExport from 'formio-export';

export default function Welcome({ agencyData, handleNav }) {
  const [awaitingCustService, setAwaitingCustService] = useState(false);
  const [allDocsSigned, setAllDocsSigned] = useState(false);

  useEffect(() => {
    document.title = `SembraCare Agency Portal - Welcome`;

    if (agencyData) {
      agencyData.map((agency) => {
        //CHECK IF ALL DOCS SIGNED
      });
    }
  });

  return (
    <main>
      <Section
        title="Welcome"
        body={
          <>
            {!awaitingCustService && !allDocsSigned && (
              <div>
                <p>
                  Hello, welcome to SembraCare’s Document Management Portal.
                  There are updated documents that require your attention. The
                  first step is to review your provider information for
                  accuracy.
                </p>
                <p>
                  You will be asked to sign documents related to the following
                  {agencyData && agencyData.length >= 2
                    ? " organizations"
                    : " organization"}
                  .
                </p>
                {agencyData &&
                  agencyData.map((agency) => (
                    <p key={agency._id}>
                      <strong>{agency.companyData.name}</strong>
                    </p>
                  ))}
                <p>Click "Next" to review provider information</p>
              </div>
            )}

            {allDocsSigned && (
              <div>
                <p>All documents are signed and up to date.</p>
                <p>
                  Visit documents link under the "User Profile" icon{" "}
                  <FaUserCircle
                    style={{ color: "#0000c2", fontSize: "1.5rem" }}
                  />{" "}
                  to view/download your signed Documents
                </p>
              </div>
            )}

            {awaitingCustService && (
              <div>
                <p>SembraCare is working on updating your information. </p>
                <p>
                  If you have not heard from our office within 2 business days,
                  please contact:
                </p>
                <Contact />
              </div>
            )}
          </>
        }
      />

      <div className="btn-bar">
        <button
          // className={termsAccepted ? "sc-btn" : "disabled-btn"}
          className="sc-btn"
          // onClick={handleSubmit}
          onClick={(e) => handleNav("Welcome", "Review-Information")}
        >
          Next
        </button>
      </div>
    </main>
  );
}
