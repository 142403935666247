import http from "./httpService";
import apiUrl from "../config.json";
import authService from "./authService";

export function registerLegacyUser(user) {
    const apiEndpoint = apiUrl.apiUrl + "/createLegacyUser";

    return http.post(apiEndpoint, user)
}

export function assignLegacyUser(user) {
    const apiEndpoint = apiUrl.apiUrl + "/assignLegacyUser";

    return http.post(apiEndpoint, user)
}

export function getUserData() {
    const apiEndpoint = apiUrl.apiUrl + "/getUserData";

    return http.post(apiEndpoint, {username:authService.getCurrentUser()}, {
        headers: { Authorization: `Bearer ${authService.getJwt()}` } });
}

export function saveUserData(username, userData, userJWT) {
    const apiEndpoint = apiUrl.apiUrl + "/saveUserData";
    
    return http.post(apiEndpoint, {username:username,userData:userData}, {
        headers: { Authorization: `Bearer ${userJWT}` } });
}

export default {
    registerLegacyUser,
    getUserData,
    saveUserData
}