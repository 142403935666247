import { PDFDocument, StandardFonts, lineSplit } from "pdf-lib";
import messengerDoc from "../../../assets/pdfs/2024ParticipationAgreementBlank.pdf";
import updatedCSA from "../../../assets/pdfs/UpdatedCSA.pdf"
import { getUserData } from "../../../services/authService";

export default function DocFill(selectedDoc, signatureHold, taxIDList) {
  // console.log(selectedDoc.docTaxID);
  const drawText = (page, x, y, size, font, value) => {
    page.drawText(value, { x: x, y: y, size: size, font: font });
  };

  const drawLine = (page, start, end, thickness) => {
    page.drawLine({ start: start, end: end, thickness: thickness });
  };

  const drawImage = (page, x, y, width, height, image) => {
    page.drawImage(image, { x: x, y: y, width: width, height: height });
  };

  const drawMessenger = async (pages, timesFont, userData) => {
    const today = new Date();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    drawText(pages[0], 123, 668, 10, timesFont, today.getDate().toString());
    drawText(
      pages[0],
      180,
      668,
      10,
      timesFont,
      month[today.getMonth().toString()]
    );

    let page8y = 642;
    drawText(pages[8], 330, page8y, 12, timesFont, selectedDoc.agencyName);
    drawText(pages[8], 365, page8y - 58, 11, timesFont, userData.userData.name);
    drawText(pages[8], 365, page8y - 72, 11, timesFont, userData.userData.title);

    drawText(pages[8], 330, page8y - 127, 11, timesFont, selectedDoc.agencyAddress);
    drawText(pages[8], 330, page8y - 141, 11, timesFont, selectedDoc.agencyCityStateZip);
    drawText(pages[8], 365, page8y - 155, 11, timesFont, userData.userData.name);
    // addEIN
    drawText(pages[8], 72, page8y - 200, 11, timesFont, `EIN: ${selectedDoc.docTaxID}`);

    let page11y = 650;
    drawText(pages[11], 95, page11y, 12, timesFont, "Agency: " + selectedDoc.agencyName);
    drawText(pages[11], 95, page11y - 15, 11, timesFont, "Business Address: " +
      selectedDoc.agencyAddress + " " + selectedDoc.agencyCityStateZip);
    drawText(pages[11], 95, page11y - 30, 11, timesFont, "Owner/Registrant: " +
      userData.userData.name + " (" + userData.userData.title + ")");

    if (selectedDoc.agencyEmail.length > 70)
      drawText(pages[11], 95, page11y - 45, 12, timesFont, "Email: "
        + selectedDoc.agencyEmail.substring(0, 67) + "...");
    else
      drawText(pages[11], 95, page11y - 45, 12, timesFont, "Email: " + selectedDoc.agencyEmail);

    drawText(pages[11], 95, page11y - 60, 12, timesFont, "Phone: " + selectedDoc.agencyPhone);
    drawText(pages[11], 95, page11y - 75, 12, timesFont, "Owner/Registrar Phone: "
      + userData.userData.mobile);

    drawLine(pages[11], { x: 75, y: page11y - 85 }, { x: 500, y: page11y - 85 }, 2);

    drawText(pages[11], 100, page11y - 110, 12, timesFont, "EIN");
    drawText(pages[11], 200, page11y - 110, 12, timesFont, "License #");
    drawText(pages[11], 275, page11y - 110, 12, timesFont, "Type");
    drawText(pages[11], 375, page11y - 110, 12, timesFont, "NPI");
    drawText(pages[11], 475, page11y - 110, 12, timesFont, "Fee");

    drawLine(pages[11], { x: 90, y: page11y - 115 }, { x: 500, y: page11y - 115 }, 1);

    let agencyFee = 700;
    let taxIDFee = 200;
    let licenseFee = 100;
    let totalFee = 0;
    let yMovement = 0;

    taxIDList.map((taxIDObject) => {
      drawText(pages[11], 100, page11y - 130 - yMovement, 12, timesFont, taxIDObject.taxID);
      drawText(pages[11], 475, page11y - 130 - yMovement, 12, timesFont, "$" + taxIDFee.toString());

      totalFee += taxIDFee;
      yMovement += 15;

      taxIDObject.taxIDData.map((taxIDData) => {
        drawText(pages[11], 200, page11y - 130 - yMovement, 12, timesFont, taxIDData.licenseNumber);
        drawText(pages[11], 275, page11y - 130 - yMovement, 12, timesFont, taxIDData.type);
        drawText(pages[11], 375, page11y - 130 - yMovement, 12, timesFont, taxIDData.npi);
        drawText(pages[11], 475, page11y - 130 - yMovement, 12, timesFont,
          "$" + licenseFee.toString());

        totalFee += licenseFee;
        yMovement += 15;
      });
      yMovement += 10;
    });
    drawText(pages[11], 100, page11y - 130 - yMovement, 12, timesFont, "Agency Fee");
    drawText(pages[11], 475, page11y - 130 - yMovement, 12, timesFont, "$" + agencyFee.toString());

    totalFee += agencyFee;
    yMovement += 10;

    drawLine(pages[11], { x: 90, y: page11y - 130 - yMovement }
      , { x: 500, y: page11y - 130 - yMovement }, 1);

    yMovement += 20;

    drawText(pages[11], 100, page11y - 130 - yMovement, 12, timesFont, "TOTAL");
    drawText(pages[11], 475, page11y - 130 - yMovement, 12, timesFont, "$" + totalFee.toString());

    drawText(pages[11], 50, page11y - 150 - yMovement, 12, timesFont,
      "NOTE: There will be a separate Participation Agreement for each EIN listed. The total amount listed here");
    drawText(pages[11], 60, page11y - 165 - yMovement, 12, timesFont,
      "accounts for all EINs and is the total amount due for all Participation Agreements, cumulatively.");

    //HAVEN'T FIXED BELOW YET
    let page12y = 488;
    drawText(pages[12], 95, page12y, 12, timesFont, selectedDoc.agencyName);
    drawText(pages[12], 120, page12y - 55, 11, timesFont, userData.userData.name);
    drawText(pages[12], 120, page12y - 70, 11, timesFont, userData.userData.title);
  };

  const drawUpdatedCSA = async (pages, timesFont, userData) => {
    let xStart = 145;
    // let yStart = 400;
    let yStart = 470;
    const today = new Date();
    drawText(pages[0], xStart, yStart - 25, 12, timesFont, userData.userData.name);
    drawText(pages[0], xStart, yStart - 50, 12, timesFont, userData.userData.title);
    drawText(pages[0], xStart, yStart - 75, 12, timesFont, today.toDateString());
    drawText(pages[0], xStart + 225, yStart - 75, 12, timesFont, today.toDateString());
    // drawText(pages[0], xStart+200, 510, 12, timesFont, today.getDate().toString());
  };

  const fillDoc = async (docPDF) => {
    const userData = getUserData();
    const pdfBuffer = await fetch(docPDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    pdfDoc.setTitle(selectedDoc.docTitle);

    // Embed the Helvetica and Times font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const timesFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

    // Get the first page of the document
    const pages = pdfDoc.getPages();

    if (selectedDoc.docName == "MessengerParticipation") {
      if (signatureHold != "") {
        const pngSignatureBytes = await fetch(signatureHold).then((res) =>
          res.arrayBuffer()
        );
        const pngImg = await pdfDoc.embedPng(pngSignatureBytes);
        const pngDims = pngImg.scale(0.09);
        drawImage(pages[8], 350, 597, pngDims.width, pngDims.height, pngImg);
        drawImage(pages[12], 95, 447, pngDims.width, pngDims.height, pngImg);
      }
      drawMessenger(pages, timesFont, userData);
    }

    if (selectedDoc.docName == "UpdatedCSA") {
      if (signatureHold != "") {
        const pngSignatureBytes = await fetch(signatureHold).then((res) =>
          res.arrayBuffer()
        );
        const pngImg = await pdfDoc.embedPng(pngSignatureBytes);
        const pngDims = pngImg.scale(0.07);
        // drawImage(pages[0], 370, 570, pngDims.width, pngDims.height, pngImg);
        drawImage(pages[0], 150, 465, pngDims.width, pngDims.height, pngImg);
      }
      drawUpdatedCSA(pages, timesFont, userData);
    }

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });
    return pdfBytes;
  };

  if (selectedDoc.docName == "MessengerParticipation")
    return fillDoc(messengerDoc);
  else if (selectedDoc.docName == "UpdatedCSA")
    return fillDoc(updatedCSA);
  else return "DOC ERROR";
}
