import http from "./httpService";
import apiUrl from "../config.json";
import jwtDecode from "jwt-decode"

const apiEndpoint = apiUrl.apiUrl + "/auth";
const tokenKey = 'tokenSCAgency';

// http.setJwt(getJwt());

export async function login(username, password) {
    const userResponse = await http.post(apiEndpoint, { username, password });

    if (userResponse.data.error) {
        return userResponse.data.error;
    } else {
        localStorage.setItem(tokenKey, userResponse.data);
        return null;
    }
}

export function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}

export function logout() {
    localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt).username;
    } catch (ex) {
        return null;
    }
}

export function getUserData() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt);
    } catch (ex) {
        return null;
    }
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}

export default {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    getUserData,
    getJwt
}