export default function Checkbox({ label, handleCheckbox, disableInput }) {
  return (
    <label className="checkbox-input">
      {/* This div wraps the label and allows entire label to be clicked  */}
      <div>
        <input type="checkbox" onChange={handleCheckbox} disabled={disableInput}></input>
        {label}
      </div>
    </label>
  );
}
