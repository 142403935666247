import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import agencyService from "../../../services/agencyService";
import { getUserData } from "../../../services/authService";
import AttnDocs from "./attn-docs";
import Section from "../../layout/section";
import DocInstructions from "./doc-instructions";
import Button from "../../button";
import TimedUserMessage from "../../timed-user-message";
// import { FormBuilder } from '@formio/react';
// import formTest from '../../../assets/objects/formTest.json'

export default function Documents({
  agencyData,
  attnDocs,
  handleNav,
  handleUserUpdate,
  taxIDFullList,
}) {
  const [userData, setUserData] = useState({});
  let date = moment()
    .tz("America/New_York")
    .format("dddd, MMMM Do YYYY, h:mm:ss a");

  const handleSubmit = async () => {
    await agencyData.forEach(async (agency) => {
      if (agency.messengerData.participationDocSigned) {
        handleNav("Review-Documents", "Success");
      } else {
        await agencyService.saveAgencyData(agency._id, {
          messengerData: {
            ...agency.messengerData,
            eventDates: {
              ...agency.messengerData.eventDates,
              messengerDocsSigned: date,
            },
            participationDocSigned: true,
          },
        });
        handleNav("Review-Documents", "Success");
      }
    });
  };

  // const [isMessageVisible, setIsMessageVisible] = useState(false);
  // const [timedMessage, setTimedMessage] = useState("");
  // // const [timedMessageType, setTimedMessageType] = useState("");
  // const handleTimedMessage = (message, type) => {
  //   setTimedMessage(message);
  //   setIsMessageVisible(!isMessageVisible);
  //   // setTimedMessageType(type);
  // };

  useEffect(() => {
    document.title = `SembraCare Agency Portal - Documents`;
    const userData = getUserData();
    setUserData(userData);
  }, []);

  return (
    <main>
      {/* <button onClick={() => handleTimedMessage("TEST")}>TEST</button>
      <TimedUserMessage
        // type={timedMessageType}
        message={timedMessage}
        variant="success"
        isVisible={isMessageVisible}
      /> */}
      {/* <Section title=" Review Documents" body={<DocInstructions />} /> */}
      <Section
        title="Attention Documents"
        body={
          <AttnDocs
            agencyData={agencyData}
            userData={userData}
            attnDocs={attnDocs}
            handleUserUpdate={handleUserUpdate}
            taxIDFullList={taxIDFullList}
            // handleTimedMessage={handleTimedMessage}
          />
        }
      />
      {attnDocs.length === 0 && (
        <div className="btn-bar">
          <Button
            // disabledConditions={attnDocs.length === 0 ? false : true}
            label="Next"
            handleClick={handleSubmit}
          />
        </div>
      )}
      {/* <Form src={formTest} onSubmit={console.log} /> */}
      {/* <FormBuilder form={formTest} onSubmit={console.log} /> */}
    </main>
  );
}
