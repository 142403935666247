import { React, useState, useEffect, useRef } from "react";
import { FaUserCircle } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { IoDocumentsOutline } from "react-icons/io5";
const UserMenu = ({ handleLogoutModal }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  let menuRef = useRef();

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    });
  }, []);

  return (
    <div>
      <div ref={menuRef}>
        <button onClick={handleMenu} tabIndex="1">
          <FaUserCircle className="user-menu-icon" />
        </button>
        {showMenu && (
          <section className="user-menu">
            <div className="item" onClick={handleLogoutModal}>
              <RiLogoutBoxRLine className="item-icon" />
              <a href="#">Logout</a>
            </div>
            <div className="item">
              <IoDocumentsOutline className="item-icon" />
              <a href="/final-documents">Documents</a>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default UserMenu;
