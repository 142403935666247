import React from "react";
import CollapsedNav from "./collapsed-nav-btn";
import UserMenu from "../userMenu";

const Header = ({ showCollapsedNav, handleLogoutModal }) => {
  return (
    <header>
      <CollapsedNav showCollapsedNav={showCollapsedNav} />
      <h1>SembraCare Provider Portal</h1>
      <UserMenu handleLogoutModal={handleLogoutModal} />
    </header>
  );
};

export default Header;
